import React from 'react';
import c from './FondPage.module.scss'
import FondSection from "../../components/FondSection/FondSection";

function FondPage() {
    return (
        <div className={c.blokc}>
            <FondSection />
        </div>
    );
}

export default FondPage;